import Logo from './../images/logo.png';
import './../css/Login.css';
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useAppContext } from "./../libs/contextLib";
import LoaderButton from "./LoaderButton";


function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
        Auth.signIn(email, password);
        //alert("Logged inn");
            //console.log(Auth.signIn(email, password));

        userHasAuthenticated(true);
      } catch (e) {
        alert(e.message);
        setIsLoading(false);
      }
  }

  return (

  <div className="row">
        <div className="logo-row">
          <img src={Logo} alt="logo"/>

    <div className="Login">
          <Form onSubmit={handleSubmit}>
            <Form.Group size="lg" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
             <LoaderButton
              size="lg"
              type="submit"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Login
            </LoaderButton>
          </Form>
          </div></div></div>

  );
}

export default Login;
