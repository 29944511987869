import React from 'react';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "../css/Datatable.css";
import $ from 'jquery';
import { API } from 'aws-amplify';
import awsconfig from './../aws-exports';
import { useEffect, useReducer } from 'react';
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

export default function List() {
API.configure(awsconfig);
$(document).ready(function () {
    $('#jobtable').DataTable();
});
useEffect(() => {
    getJobList();
}, []);
const initialState: AppState = {
  jobs: []
};
const reducer = (state: AppState, action: Action) => {
  return { ...state, jobs: action.payload };
};
const [state, dispatch] = useReducer(reducer, initialState);

 const getJobList = async () => {
    const jobData = await API.get('sentimentJob', '/list', '');
    var resultData = jobData['body'];
    if(jobData === 'Something went wrong!') {
        resultData = [];
        return resultData;
    }
    if(jobData['statusCode'] === 200) {
        resultData = JSON.parse(jobData['body']);
    }
    dispatch({
      type: 'QUERY',
      payload: resultData,
    });
  };
return (
    <div className="MainDiv">
    <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
                    <LinkContainer to="/" className="logoContainer">
                      <Navbar.Brand className="font-weight-bold text-muted">
                        <div id="logo" className="logo"></div>
                      </Navbar.Brand>
                    </LinkContainer>
                    <h1 className="midtext">Sentiment Analysis</h1>
                    <Link to="/home" className="btn btn-info btn-lg mr-3 homebutton fright">
                      Home
                    </Link>
                    <Navbar.Toggle />
                  </Navbar>
          <div className="container">
              {state.jobs.length  ? (
                  <table id="jobtable" className="display">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Category</th>
                            <th>Input File</th>
                            <th>Output File</th>
                            <th>Status</th>
                            <th>Created At (UTC)</th>
                            <th>Updated At (UTC)</th>
                        </tr>
                    </thead>
                    <tbody>
                       {state.jobs.map((job, index) => (
                        <tr key={`job-${index}`}>
                            <td>{index + 1}</td>
                            <td>{job.title}</td>
                            <td>{job.email}</td>
                            <td>{job.category}</td>
                            <td>{job.infile}</td>
                            <td>{job.outfile}</td>
                            <td>{job.jobStatus}</td>
                            <td>{job.createdAt}</td>
                            <td>{job.updatedAt}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                     ): null}

            </div>
          </div>
  );
}