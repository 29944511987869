import { Link } from "react-router-dom";
import "./../css/Template.css";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";

export default function Template() {

  function renderHome() {
    return (
    <div className="MainDiv">
        <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
            <LinkContainer to="/" className="logoContainer">
                <Navbar.Brand className="font-weight-bold text-muted">
                    <div id="logo" className="logo"></div>
                </Navbar.Brand>
            </LinkContainer>
        <h1 className="midtext">Sentiment Analysis</h1>

        <Navbar.Toggle />
        </Navbar>
        <div className="pt-3 containerDiv">
            <Link to="/home" className="btn btn-info btn-lg mr-3 homebutton">
                Home
            </Link>
            <Link to="/list" className="btn btn-info btn-lg mr-3 homebutton">
                Dashboard
            </Link>
        </div>
    </div>
    );
  }


  return (
    <div className="Home">
      {renderHome()}
    </div>
  );
}
