import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { AppContext } from "./../libs/contextLib";
import Routes from "./../Routes";
import "./../css/App.css";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
    document.title = 'Aifluence Influencer Marketing - Sentiment Analysis Tool';

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {

    setIsAuthenticating(false);
  }


  return (
    !isAuthenticating && (
      <div className="App container py-3">

        <ErrorBoundary>
          <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <Routes />
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    )
  );
}

export default App;