import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import List from "./components/List";
import Template from "./components/Template";
import NotFound from "./components/NotFound";
//import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
  return (
    <Switch>
        <Route exact path="/">
            <Template />
        </Route>
        <UnauthenticatedRoute exact path="/home">
            <Home />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/login">
                    <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/list">
                    <List />
                </UnauthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}