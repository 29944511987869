import React, { useRef, useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "../css/Home.css?v=1";
import Form from "react-bootstrap/Form";
import LoaderButton from "./LoaderButton";
import S3 from "react-aws-s3";
import * as CSV from 'csv-string';
import Alert from 'react-bootstrap/Alert'
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import Amplify, { API } from 'aws-amplify';
import awsconfig from './../aws-exports';
import { Link } from "react-router-dom";



export default function Home () {
    const [note] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const file = useRef(null);
    const buttonRef = useRef("accept-button");
    const [content, setContent] = useState("");
    const [status, setStatus] = React.useState(0) // 0: no show, 1: show yes, 2: show no.
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [msgContent, setMsgContent] = useState("");
    const [msgType, setMsgType] = useState("");
    Amplify.configure(awsconfig);
    API.configure(awsconfig);
    const radioHandler = (status) => {
        document.getElementById("uploadData").reset();
        setStatus(status);
      };
    const fileInput= useRef();

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

    function validateForm() {
        if(status === 0) {
            return;
        }
        return true;
    }
  async function handleSubmit(event) {
    event.preventDefault();
    if(status === 0) {
        setMsgContent("Please add text / file to upload.");
        setMsgType("danger");
        setVisibleAlert(true);
        return;
    }
    setIsLoading(true);
    let file = "";
    let newFileName = "";
    const title = document.getElementById('title').value.replace(/ /g, '_');
    const email = document.getElementById('email').value;
    const category = document.getElementById('category').value;
    if(status===1) {
         const data = document.getElementById('textData').value;
                const arr = CSV.parse(data);
                const str = CSV.stringify(arr);
                var blob = new Blob([str], { type: 'text/csv' });
                file = new File([blob], "", {type: "text/csv"});
                newFileName = title+".csv";
    } else {
         file = fileInput.current.files[0];
         if (file.current && file.current.size > 5000000) {
               alert(
                    `Please pick a file smaller than ${5000000 /
                      1000000} MB.`
                  );
               return;
             }
         newFileName = title+"_"+fileInput.current.files[0].name;
    }
      const uuid = Math.random().toString(36).substring(2) + Date.now().toString(36);
      var s3Config = [];
      s3Config["bucketName"] =  "sentimentiment-input";
      s3Config["region"] =  "us-east-2";
      s3Config["accessKeyId"] = process.env.REACT_APP_AccessKey || awsconfig['Storage']['AWSS3']['accessKeyId'];
      s3Config["secretAccessKey"] =  process.env.REACT_APP_SecKey || awsconfig['Storage']['AWSS3']['secretAccessKey'];
      const ReactS3Client = new S3(s3Config);
      ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data.status === 204) {
          addJobStatus(uuid,newFileName,email,title,category)
          runJOob(uuid,newFileName,email,title)
          setMsgContent("Data submitted for analysis, you will receive an email once it done!");
          setMsgType("success");
        } else {
          setMsgContent("Something went wrong. Please try again later!");
          setMsgType("danger");
        }
        handleClear(event);
        setIsLoading(false);
        setVisibleAlert(true);

      });

  }

  async function addJobStatus(id,fname,email,title,category) {
    const data = {
        body: {
            id: id,
            fname: fname,
            email: email,
            title:title,
            category:category
        }
    };
      await API.post('sentimentJob', '/status', data);
      //alert('Job Initiated');
  }

  async function runJOob(uuid,newFileName,email,title) {
      const data = {
          body: {
              id: uuid,
              fname: newFileName,
              email: email,
              name: title
          }
      };
        await API.post('sentimentJob', '/upload', data);
        //alert('Job Started');
    }


  async function handleClear(e) {
    setVisibleAlert(false);
    if(status === 2) {
        document.getElementById("uploadData").reset();
    } else {
        document.getElementById('title').value = '';
        document.getElementById("textData").value = '';
        setContent("");
    }

  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        //const notes = await loadNotes();
        //setNotes(notes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);


function renderTextArea() {
    return (
    <Form.Group className='form-horizontal'>
    <p></p>
    <div className="form-group row">
        <label htmlFor="email" className="col-sm-2 col-form-label">Analysis Name:</label>
        <div className="col-sm-10">
          <input type="text" placeholder="Analysis Name" className="form-control" id="title" required/>
        </div>
    </div>
    <div className="form-group row">
        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Email:</label>
        <div className="col-sm-10">
          <input type="email" placeholder ="email@example.com" className="form-control" id="email" required/>
        </div>
    </div>
     <div className="form-group row">
                        <label htmlFor="category" className="col-sm-2 col-form-label">Category:</label>
                        <div className="col-sm-10">
                          <select name="category" id="category" className="form-control" required>
                            <option value="">Select Category</option>
                            <option value="Entertainment">Entertainment</option>
                            <option value="Cricket">Cricket</option>
                            <option value="Politics">Politics</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
            </div>
    <div className="form-group row">
      <label htmlFor="exampleFormControlTextarea1" className="col-sm-2 col-form-label">Sentiments:</label>
      <div className="col-sm-10">
        <textarea placeholder="Paste values here with new lines... please make sure the string contains, (comma) should be enclosed by double quotes" className="form-control" value={content} id="textData" onChange={(e) => setContent(e.target.value)} required></textarea>
      </div>
    </div>
    </Form.Group>


)};

function renderUploadForm() {
    return (

        <Form.Group className='form-horizontal'>
        <p></p>
        {note.attachment && (
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={note.attachmentURL}
            >
              {formatFilename(note.attachment)}
            </a>
          </p>
        )}
         <div className="form-group row">
          <label htmlFor="email" className="col-sm-2 col-form-label">Analysis Name:</label>
          <div className="col-sm-10">
            <input type="text" placeholder="Analysis Name" className="form-control" id="title" required/>
          </div>
        </div>
        <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Email:</label>
            <div className="col-sm-10">
              <input type="email" placeholder ="email@example.com" className="form-control" id="email" required/>
            </div>
        </div>
        <div className="form-group row">
                    <label htmlFor="category" className="col-sm-2 col-form-label">Category:</label>
                    <div className="col-sm-10">
                      <select name="category" id="category" className="form-control" required>
                        <option value="">Select Category</option>
                        <option value="Entertainment">Entertainment</option>
                        <option value="Cricket">Cricket</option>
                        <option value="Politics">Politics</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
        </div>
        <div className="form-group row">
            <label htmlFor="file" className="col-sm-2 col-form-label">Sentiments File:</label>
            <div className="col-sm-10">
              <Form.Control onChange={handleFileChange} type="file" accept=".csv" ref={fileInput} required/>
            </div>
        </div>
      </Form.Group>
)};


  function renderForm() {
    return (

      <div className="Notes">
              <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
                <LinkContainer to="/" className="logoContainer">
                  <Navbar.Brand className="font-weight-bold text-muted">
                    <div id="logo" className="logo"></div>
                  </Navbar.Brand>
                </LinkContainer>
                <h1 className="midtext">Sentiment Analysis</h1>
                <Link to="/list" className="btn btn-info btn-lg mr-3 homebutton fright">
                  Dashboard
                </Link>
                <Navbar.Toggle />
              </Navbar>
            <div className="uploadData">
              <Alert show={visibleAlert} variant={msgType} onClose={() => setVisibleAlert(false)} dismissible>{msgContent}</Alert>
              <div className="form-check form-check-inline">
                <input type="radio" name="dataType" id="inlineRadio1" value="paste" className="form-check-input radioButton" defaultChecked={status === 1} onClick={(e) => radioHandler(1)}/>
                <label className="form-check-label" htmlFor="inlineRadio1">Paste</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="radio" name="dataType" id="inlineRadio2" value="upload" className="form-check-input radioButton" defaultChecked={status === 2} onClick={(e) => radioHandler(2)} />
                <label className="form-check-label" htmlFor="inlineRadio2">Upload</label>
              </div>
            </div>
            <Form onSubmit={handleSubmit} id="uploadData"  className="uploadDataForm">
            {status === 1 && renderTextArea()}
            {status === 2 && renderUploadForm()}
            <div className="uploadData">
              <LoaderButton className="buttonClr"
                size="lg"
                type="submit"
                isLoading={isLoading}
              >
                Upload
              </LoaderButton>
              <LoaderButton
                size="lg"
                variant="danger"
                ref={buttonRef}
                onClick={handleClear}
                disabled={!validateForm()}
              >
                Clear
              </LoaderButton>
              </div>
            </Form>
        </div>
    );
  }

  return (
    <div className="Home">
      {renderForm()}
    </div>
  );
}
